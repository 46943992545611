/* eslint-disable react/jsx-props-no-spreading */
import '@fontsource/poppins';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import React from 'react';

export default function AddHubBannerForm({
  inputAds,
  setInputAds,
  updateAds,
  setUpdateAds,
}) {
  const handleStartDateChange = (newDate) => {
    if (newDate) {
      setInputAds({
        ...inputAds,
        start_dates: newDate.format('YYYY-MM-DD'),
        start_times: newDate.format('HH:mm:ss'),
      });
    }
  };

  const handleEndDateChange = (newDate) => {
    setInputAds({
      ...inputAds,
      end_dates: newDate ? newDate.format('YYYY-MM-DD') : '',
      end_times: newDate ? newDate.format('HH:mm') : '',
    });
  };

  return (
    <>
      {inputAds && (
        <>
          <div className='flex px-2 py-4'>
            <div className='col-span-3 w-full px-4 py-2'>
              <label className='block text-sm font-medium leading-6 text-gray-600'>
                Banner Title*
              </label>
              <div className='mt-2'>
                <input
                  placeholder='Enter Title'
                  style={{ height: '32px', fontSize: 14 }}
                  className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  value={inputAds?.ads_title || updateAds?.ads_title || ''}
                  onChange={(e) =>
                    inputAds
                      ? setInputAds({ ...inputAds, ads_title: e.target.value })
                      : setUpdateAds({
                          ...updateAds,
                          ads_title: e.target.value,
                        })
                  }
                />
              </div>
            </div>

            <div className='col-span-3 w-1/3 px-4 py-2'>
              <label className='block text-sm font-medium leading-6 text-gray-600'>
                Select Role*
              </label>
              <div className='mt-2'>
                <select
                  style={{ height: '56px', width: '200px', fontSize: 14 }}
                  value={inputAds ? inputAds.ads_type : updateAds.ads_type}
                  onChange={(e) =>
                    inputAds
                      ? setInputAds({ ...inputAds, type: e.target.value })
                      : setUpdateAds({ ...updateAds, ads_type: e.target.value })
                  }
                  className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                >
                  <option value=''>Select one</option>
                  <option value='personal.specialist'>Hub-Specialist</option>
                  <option value='personal.allied-healthcare'>
                    Hub-Allied Healthcare
                  </option>
                  <option value='org.healthcare'>Hub-Organisation</option>
                  <option value='personal.gp'>Hub-GP Locum</option>
                  <option value='all-roles'>ALL HUB ROLES</option>
                </select>
              </div>
            </div>

            <div className='px-1 sm:col-span-3'>
              <label className='block py-2 text-sm font-medium leading-6 text-gray-600'>
                Start Date/Time*
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{ width: '250px' }}
                  className='bg-white'
                  format='DD MMM YYYY-hh:mm A'
                  value={
                    inputAds?.start
                      ? dayjs(inputAds?.start).tz('Asia/Singapore')
                      : null
                  }
                  onChange={handleStartDateChange}
                />
              </LocalizationProvider>
            </div>

            <div className='px-2 sm:col-span-3'>
              <label className='block py-2 text-sm font-medium leading-6 text-gray-600'>
                End Date/Time*
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{ width: '250px' }}
                  className='bg-white'
                  format='DD MMM YYYY-hh:mm A'
                  value={
                    inputAds?.end
                      ? dayjs(inputAds?.end).tz('Asia/Singapore')
                      : null
                  }
                  onChange={handleEndDateChange}
                />
              </LocalizationProvider>
            </div>
          </div>
        </>
      )}
    </>
  );
}
