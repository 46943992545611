export const mainRoute = {
  DATABASE_APP_USERS: '/',
  DATABASE_CLINIC_BRANCH: '/clinic-branch',
  DATABASE_ORGANISATIONS: '/organisation',
  DATABASE_ORGANISATION_LICENSE: '/organisation-license',
  DATABASE_PAYMENT_REQUEST: '/payment-request',
  DATABASE_CLINIC_PAYMENT: '/clinic-payment',
  DATABASE_DOWNLOAD_LIST: '/download-list',
  DATABASE_MASTER_DATA: '/master-data',
  ANNOUNCEMENT_MARKETING: '/announcement-marketing',
  ANNOUNCEMENT_INTERVIEW: '/announcement-interview',
  ANNOUNCEMENT_NOTIFICATION: '/announcement-notification',
  ANNOUNCEMENT_CHAT: '/announcement-chats',
  MISC_RESOURCES: '/resources',
  MISC_AUDIT_LOG: '/audit-log',
  MISC_EVENT_MANAGEMENT: '/event-management',
  MISC_CERTIFICATE: '/event-certificate',
  MISC_REJECTED_VERIFICATION: '/rejected-verification',
  MISC_SUPER_ACCESS: '/super-access',
  DETAIL_LICENSE: '/detail-license/:licenseId',
  CREATE_ACCOUNT_DOCTOR: '/create-account-doctor',
  CREATE_ACCOUNT_CLA: '/create-account-cla',
  CREATE_ACCOUNT_NURSE: '/create-account-nurse',
  EDIT_CERT_DOCTOR: '/edit-cert-doctor',
  VERIFIED_DETAIL_DOCTOR: '/verified-doctor-detail/:userId',
  VERIFIED_DETAIL_CLA: '/verified-cla-detail/:userId',
  UNVERIFIED_DETAIL_DOCTOR: '/unverified-doctor-detail/:userId',
  UNVERIFIED_DETAIL_CLA: '/unverified-cla-detail/:userId',
  VERIFIED_DETAIL_NURSE: '/verified-nurse-detail/:userId',
  UNVERIFIED_DETAIL_NURSE: '/unverified-nurse-detail/:userId',
  UNVERIFIED_DETAIL_ACCOUNT: '/unverified-account-detail/:userId',
  VERIFICATION_CLINIC: '/verification-clinic',
  VERIFICATION_ORGANISATION: '/verification-organisation',
  VERIFIED_ORGANISATION: '/verified-organisation/:orgId',
  VERIFIED_CLINIC: '/verified-clinic/:clinicId',
  VIEW_BRANCH: '/view-branch/:orgId',
  VIEW_LISTING_DETAIL: '/view-listing-detail/:jobsId',
  VIEW_LISTING_DETAIL_NURSE: '/view-listing-detail-nurse/:jobsId',
  CREATE_CLINIC: '/create-clinic/:orgId',
  DETAIL_CLINIC: '/detail-clinic/:clinicId',
  CLINIC_SETTING: '/clinic-setting/:clinicId',
  ADDITIONAL_INFO: '/add-additional-info/:clinicId',
  CREATE_ORGANISATION: '/create-organisation',
  CREATE_ADMIN_ORGANISATION: '/create-admin-organisation/:orgId',
  ORGANISATION_FINANCE: '/organisation-finance',
  LIST_ADMIN_ORGANISATION: '/view-admin-org/:orgId',
  CREATE_NEW_EVENT: '/create-new-event',
  UPDATE_LISTED_EVENT: '/update-listed-event',
  UPDATE_UNLISTED_EVENT: '/update-unlisted-event',
  UPDATE_HISTORY_EVENT: '/update-history-event',
  DUPLICATE_PAST_EVENT: '/duplicate-event',
  VIEW_DETAIL_PAST_EVENT: '/view-detail-past-event',
  CREATE_LEARNING_JOURNEY: '/create-learning-journey',
  UPDATE_LEARNING_JOURNEY: '/update-learning-journey',
  EVENT_QUESTIONNARE: '/event-questionnare',
  EVENT_REPORT: '/event-report',
  VIDEO_ANALYTIC: '/video-analytic',
  CREATE_CERTIFICATE: '/create-certificate',
  CREATE_CERTIFICATE_TEMPLATE: '/create-certificate-template',
  EDIT_CERTIFICATE_TEMPLATE: '/edit-certificate-template',
  ADD_WEB_CAMPAIGN: '/add-web-campaign',
  ADD_MOBILE_BANNER: '/add-mobile-banner',
  SAVE_CAMPAIGN: '/save-campaign',
  UPDATE_MOBILE_BANNER: '/update-mobile-banner/:adsId',
  UPDATE_WEB_CAMPAIGN: '/update-web-campaign/:bannerId',
  CREATE_RESOURCE: '/create-resource',
  UPDATE_RESOURCE: '/update-resource/:resId',
  CREATE_SUPER_ADMIN: '/create-super-admin',
  EDIT_REQUESTINTERVIEW: 'edit-request-interview/:interviewId',
  CREATE_NEW_SLOT: '/create-new-slot/:clinicId',
  EDIT_PAST_SLOT: '/edit-past-slot/:jobsId',
  CREATE_NEW_SLOT_NURSE: '/create-new-slot-nurse/:id',
  EDIT_PAST_SLOT_NURSE: '/edit-past-slot-nurse/:id',
  
  //HUB
  ADD_HUB_BANNER: '/add-hub-banner',
  UPDATE_HUB_BANNER: '/update-hub-banner/:adsId'
};

export const menuDatabase = [
  { id: 1, name: 'App Users', to: mainRoute.DATABASE_APP_USERS, count: 0 },
  {
    id: 2,
    name: 'Organisations',
    to: mainRoute.DATABASE_ORGANISATIONS,
    count: 0,
  },
  {
    id: 3,
    name: 'Organisation License',
    to: mainRoute.DATABASE_ORGANISATION_LICENSE,
    count: 0,
  },
  {
    id: 4,
    name: 'Clinic Branches',
    to: mainRoute.DATABASE_CLINIC_BRANCH,
    count: 0,
  },
  // {
  //   id: 5,
  //   name: 'Payment Request',
  //   to: mainRoute.DATABASE_PAYMENT_REQUEST,
  //   count: 0,
  // },
  // {
  //   id: 6,
  //   name: 'Clinic Payment',
  //   to: mainRoute.DATABASE_CLINIC_PAYMENT,
  //   count: 0,
  // },
  {
    id: 5,
    name: 'Download List',
    to: mainRoute.DATABASE_DOWNLOAD_LIST,
    count: 0,
  },
  {
    id: 6,
    name: 'Master Data',
    to: mainRoute.DATABASE_MASTER_DATA,
    count: 0,
  },
];

export const menuAnnouncement = [
  {
    id: 1,
    name: 'Marketing Content',
    to: mainRoute.ANNOUNCEMENT_MARKETING,
    count: 0,
  },
  {
    id: 2,
    name: 'Interview Application',
    to: mainRoute.ANNOUNCEMENT_INTERVIEW,
    count: 0,
  },
  {
    id: 3,
    name: 'Send Notification',
    to: mainRoute.ANNOUNCEMENT_NOTIFICATION,
    count: 0,
  },
  { id: 4, name: 'Chat', to: mainRoute.ANNOUNCEMENT_CHAT, count: 0 },
];

export const menuMISC = [
  // { id: 1, name: 'Audit Log', to: mainRoute.MISC_AUDIT_LOG, count: 0 },
  { id: 1, name: 'Resources', to: mainRoute.MISC_RESOURCES, count: 0 },
  {
    id: 2,
    name: 'Event Management',
    to: mainRoute.MISC_EVENT_MANAGEMENT,
    count: 0,
  },
  {
    id: 3,
    name: 'Certificate',
    to: mainRoute.MISC_CERTIFICATE,
    count: 0,
  },
  // {
  //   id: 5,
  //   name: 'Rejected Verification',
  //   to: mainRoute.MISC_REJECTED_VERIFICATION,
  //   count: 0,
  // },
  { id: 4, name: 'Super Access', to: mainRoute.MISC_SUPER_ACCESS, count: 0 },
];
